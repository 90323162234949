import React, { useState, useEffect } from "react";

function ThemeProvider(props) {
 
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const [width, setWidth] = useState();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('load', handleWindowSizeChange);
    window.addEventListener("resize", handleWindowSizeChange);    
  }, []);

  let isMobile = width <= 1024;

  const stateToPublic = {
    isMobile,
    menuOpen,
    setMenuOpen,
    subMenuOpen,
    setSubMenuOpen,
    currentYear,
    setCurrentYear,
  };

  return (
    <ThemeContext.Provider value={stateToPublic}>
      {props.children}
    </ThemeContext.Provider>
  );
}

const ThemeContext = React.createContext({});

export { ThemeContext, ThemeProvider };
