import React, { useContext } from "react";
import Navbar from "../navbar/navbar.js";
import { ThemeContext } from "../../context/ThemeContext";
import Footer from "../footer/footer.js";
import Particles from "react-tsparticles";
import CookieConsent from "react-cookie-consent";
import WhiteCircle from "../../assets/img/svg/white-circle.svg";

import "./style.scss";

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  return (
    <>
      <Navbar />
      <div
        className="layout"
        role="button"
        tabIndex="0"
        onClick={closeMenu}
        onKeyPress={closeMenu}
      >
        <Particles
          style={{ position: "fixed", zIndex: -1 }}
          params={{
            particles: {
              line_linked: {
                enable: false,
              },
              move: {
                bounce: false,
              },
              number: {
                value: 5,
              },
              size: {
                value: theme.isMobile ? 50 : 150,
                random: true,
                anim: {
                  enable: false,
                  speed: 10,
                  size_min: 0.7,
                  sync: true,
                },
              },
              shape: {
                type: "images",
                image: [{ src: WhiteCircle, height: 200, width: 200 }],
              },
            },
          }}
        />
        {children}
      </div>
      <Footer />
      <CookieConsent
        style={{ background: "#FE3C99", fontFamily: "Averta-Regular" }}
        buttonStyle={{
          background: "white",
          color: "#FE3C99",
          fontSize: "0.9rem",
          borderRadius: "25px",
          padding: "10px 15px",
        }}
        buttonText="Accetto"
      >
        Il sito utilizza i cookie per funzionare. Utilizzando i nostri servizi
        acconsenti all'uso dei cookie.
        <a
          target="_blank"
          rel="noreferrer"
          href="http://docs.acmeproduzioni.it/eucleia-website/cookie-policy-it.html"
        >
          <span style={{ fontSize: "0.8rem", color: "white" }}>
            {" "}
            Maggiori informazioni
          </span>
        </a>
      </CookieConsent>
    </>
  );
};

export default Layout;
