// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-functionalities-js": () => import("./../../../src/pages/functionalities.js" /* webpackChunkName: "component---src-pages-functionalities-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-our-integrations-js": () => import("./../../../src/pages/our-integrations.js" /* webpackChunkName: "component---src-pages-our-integrations-js" */),
  "component---src-pages-wellness-js": () => import("./../../../src/pages/wellness.js" /* webpackChunkName: "component---src-pages-wellness-js" */),
  "component---src-pages-whatsapp-ai-js": () => import("./../../../src/pages/whatsapp-ai.js" /* webpackChunkName: "component---src-pages-whatsapp-ai-js" */),
  "component---src-pages-why-eucleia-js": () => import("./../../../src/pages/why-eucleia.js" /* webpackChunkName: "component---src-pages-why-eucleia-js" */)
}

