import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Link } from "gatsby";
import LogoIsidoro from "../../assets/img/svg/logo-isidoro.svg";
import LogoRoomCloud from "../../assets/img/svg/logo-roomcloud.svg";

import "./style.scss";

const Footer = () => {

  const theme = useContext(ThemeContext)

  return (
    <footer>
      <div className="inner-footer">
        <div className="columns is-multiline m-0">
          <div className="column is-offset-1-tablet is-2-tablet contacts">
            <Link to="/come-funziona">Come funziona</Link>
            <br />
            <br />
            <Link to="/perché-eucleia">Perché Eucleia</Link>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="http://docs.acmeproduzioni.it/eucleia-website/privacy-policy-it.html"
            >
              Privacy Policy
            </a>
          </div>
          <div className="column is-2-tablet pages">
            <Link to="/le-nostre-integrazioni">Le nostre integrazioni</Link>
            <br />
            <br />
            <Link to="/whatsapp-ai">Whatsapp e AI</Link>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="http://docs.acmeproduzioni.it/eucleia-website/cookie-policy-it.html"
            >
              Cookie Policy
            </a>
          </div>
          <div className="column is-2-tablet">
            <Link to="/contatti">Contatti</Link>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eucleia.me/login"
            >
              Login
            </a>
          </div>
          <div className="column is-offset-2-tablet is-offset-2-desktop is-8-tablet is-3-desktop">
            <div className="columns is-gapless social">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/acmeproduzioni/"
                className="column is-3 is-full-mobile"
              >
                <span style={{ fontWeight: "bold" }}>Facebook</span>
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/acmeproduzioni_official/"
                className="column is-9 is-full-mobile"
              >
                <span style={{ fontWeight: "bold" }}>Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="columns m-0">
          <div className="column is-offset-1-tablet is-8-tablet ">
            <span>
              © {theme.currentYear} Eucleia by ACME Produzioni Srl - PIVA/VAT 10245151005
            </span>
          </div>
          <div className="column is-2-tablet ">
            <div className="columns is-mobile">
              <div className="column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://isidorosoftware.com/"
                  className=""
                >
                  <img src={LogoIsidoro} alt="Logo Isidoro" />
                </a>
              </div>
              <div className="column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.roomcloud.net/"
                  className=""
                >
                  <img src={LogoRoomCloud} alt="Logo RoomCloud" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
